import React from 'react'
import { IconsProps } from 'msp-integrations'

const ArrowDownFilledIcon = (props: IconsProps) => {
  return (
    <svg
      version='1.1'
      {...props}
      viewBox='0 0 9 5'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <path d="M4.5 5 9 0H0z" />
    </svg>
  )
}

export default ArrowDownFilledIcon
